// src/components/ApiDataModals.tsx
import React, {useMemo, CSSProperties, useEffect, useState } from 'react';
import { ApiDataModalItem } from './ApiDataModalItem';
import { DataCard } from './DataCard';
import { useAllHourlyUpdates } from './useAllHourlyUpdates';
import containerStyles from './ApiDataModal.module.css';

const apiUrls = [
  `https://api-proxy.enter-dev.repl.co/api/data?apiType=api1`,
  `https://api-proxy.enter-dev.repl.co/api/data?apiType=api2`,
  `https://api-proxy.enter-dev.repl.co/api/data?apiType=api3`,
  `https://api-proxy.enter-dev.repl.co/api/data?apiType=api4`,
  `https://api-proxy.enter-dev.repl.co/api/data?apiType=api5`,
  `https://api-proxy.enter-dev.repl.co/api/data?apiType=api6`,
];

const titles = [
  'LP tokens in the farm',
  'Total PancakeSwap V2 LP supply',
  'Percentage of LP supply in the farm',
  'BNB in the farm',
  'Trillion NFTART in the farm',
  'TVL in the farm in USD',
  'Daily APR',
  'Yearly APR',
  'APY Monthly compounding',
  'APY Weekly compounding',
  'BNB in 1 LP token',
  'Trillion NFTART in 1 LP token',
  '1 LP token in USD',
];


export const ApiDataModals: React.FC = () => {
  const allData = useAllHourlyUpdates(apiUrls);

  const [bnbPrice, setBnbPrice] = useState<number | null>(null);
 const [nftArtPrice, setNftArtPrice] = useState<number | null>(null);

 useEffect(() => {
   if (allData[4] && typeof allData[4] !== 'string') {
     setBnbPrice(Number(allData[4]) / 1000000000000000000);
   }
   if (allData[5] && typeof allData[5] !== 'string') {
     setNftArtPrice(Number(allData[5]) / 10);
   }
 }, [allData]);

  const lpTokenHoldingsFarm = Number(allData[0]) || 0;
   const totalSupply = Number(allData[1]) || 0;


  const percentageOfLPInFarm = useMemo(
    () => ((lpTokenHoldingsFarm / totalSupply) * 100).toFixed(2),
    [lpTokenHoldingsFarm, totalSupply]
  );

  const bnbInFarm = useMemo(
  () => (Number(allData[2] || 0) / 100) * parseFloat(percentageOfLPInFarm),
  [allData[2], percentageOfLPInFarm]
);

const nftArtHolding = useMemo(
  () => (Number(allData[3] || 0) / 100) * parseFloat(percentageOfLPInFarm) / 100000000,
  [allData[3], percentageOfLPInFarm]
);

const tvlInFarm = useMemo(() => {
  console.log('bnbPrice:', bnbPrice);
  console.log('nftArtPrice:', nftArtPrice);

  return (bnbInFarm * (bnbPrice || 0)) + (nftArtHolding * (nftArtPrice || 0));
}, [bnbPrice, nftArtPrice, bnbInFarm, nftArtHolding]);


const dailyReward = 216000000000;
const dailyAPR = (dailyReward * nftArtPrice) / tvlInFarm * 1000;
const yearlyAPR = dailyAPR * 365;
const yearlyAPRAPY = dailyAPR * 365 /100;


// Calculate APY Monthly compounding
const principalAmount = 10;
const compoundingPeriod = 12;
const compoundingPeriodWeekly = 52;
const years = 1;

const apyMonthlyCompounding = principalAmount * Math.pow((1 + yearlyAPRAPY / compoundingPeriod), (years * compoundingPeriod));
const apyWeeklyCompounding = principalAmount * Math.pow((1 + yearlyAPRAPY / compoundingPeriodWeekly), (years * compoundingPeriodWeekly));

const lpBNBHolding = (allData[6] !== undefined && typeof allData[6] !== 'string') ? BigInt(allData[6]) : BigInt(0);
const lpNFTARTHolding = (allData[7] !== undefined && typeof allData[7] !== 'string') ? BigInt(allData[7]) : BigInt(0);


// Calculate the amounts of BNB and NFTART in 1 LP token
const bnbIn1LPToken = useMemo(() => {
  if (totalSupply !== 0) {
    return Number(allData[2]) / totalSupply;
  }
  return 0;
}, [allData, totalSupply]);

const nftArtIn1LPToken = useMemo(() => {
  if (totalSupply !== 0) {
    return Number(allData[3]) / totalSupply / 1000;
  }
  return 0;
}, [allData, totalSupply]);

const LPTokenInUSD = useMemo(() => {
  return Number(allData[4]) * bnbIn1LPToken * 2;
}, [bnbIn1LPToken, nftArtIn1LPToken, bnbPrice, nftArtPrice]);



  const displayedData = [
  ...allData.slice(0, 2),
  totalSupply ? percentageOfLPInFarm : '',
  bnbInFarm,
  nftArtHolding,
  tvlInFarm,
  dailyAPR,
  yearlyAPR,
  apyMonthlyCompounding,
  apyWeeklyCompounding,
  bnbIn1LPToken,
  nftArtIn1LPToken,
  LPTokenInUSD,
];

const displayedDataStrings = displayedData.map((data) => {
  if (typeof data === 'number') {
    return data.toFixed(2);
  }
  return data.toString();
});


    return (
      <div style={styles.outerContainer}>
        <div className={containerStyles.container}>
          {titles.map((title, index) => {
            return (
              <DataCard
                key={title}
                title={title}
                data={displayedDataStrings[index] || ''}
              />
            );
          })}
        </div>
      </div>
    );
  };

const styles: { [key: string]: CSSProperties } = {
  outerContainer: {

    opacity: '60%',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '1rem',

  },
};
