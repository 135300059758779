// src/hooks/useAllHourlyUpdates.ts
import { useEffect, useState } from 'react';

export const useAllHourlyUpdates = (urls: string[]) => {
  const [data, setData] = useState<(string | number)[]>([]);

  async function fetchData(url: string): Promise<any> {
  try {
    console.log('Fetching data from', url);
    const response = await fetch(url);
    console.log('Response status', response.status);
    const data = await response.json();

    if (url.includes('coingecko')) {
      const key = Object.keys(data)[0];
      console.log('Fetched data:', data);

      const usd = data[key].usd;
      if (key === 'binancecoin') {
        return usd / 1000000000000000000;
      } else {
        return usd / 10;
      }
    } else if (url.includes('apiType=api5') || url.includes('apiType=api6')) {
      const key = Object.keys(data)[0];
      console.log('Fetched data:', data);
      return data[key].usd;
    } else {
      console.log('Fetched data:', data);
      return data.result;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return '';
  }
}


  useEffect(() => {
    const fetchAllData = async () => {
      const newData = await Promise.all(urls.map(fetchData));
      console.log('All data fetched:', newData);
      setData(newData);
      console.log('Updated data state:', data);
    };

    fetchAllData();

    const interval = setInterval(() => {
      fetchAllData();
    }, 60 * 60 * 1000);

    return () => clearInterval(interval);
  }, [urls]);

  return data;
};
