// 42 - Testnet
// 4 - Rinkeby
// 1 - Mainnet

/*
1 Mainnet
3 Ropsten
4 Rinkeby
5 Goerli
42 Kovan
56 Binance Smart Chain Mainnet
97 Binance Smart Chain Testnet
100 xDai
137 Matic
1287 Moonbase Testnet
80001 Matic Testnet
43113 Avalanche Testnet
43114 Avalanche Mainnet
*/

export const contractAddresses = {
  erc20: {
    97: '0xB61DF995ed3FEA6bB2d51e7A9C2408e8C269A3D6',
    56: '0xF7844CB890F4C339c497aeAb599aBDc3c874B67A',
  },
  erc20v2: {
    97: '0xB61DF995ed3FEA6bB2d51e7A9C2408e8C269A3D6',
    56: '0xF7844CB890F4C339c497aeAb599aBDc3c874B67A',
  },
  farm: {
    97: '0x39eb744B384fd5608B22dec7443EAeF37817EbD6',
    56: '0xa7aEB091F7129038A04d6a2B4b431cAD32135957',
  },
  weth: {
    97: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  },
}

export const supportedPools = [
  {
    id: 'NFTART',
    version: 'LP Farm',
    name: 'NFTART',
    pid: 0,
    lpAddresses: {
      56: '0x2b7fC60FD13F32fEd8730113A14e3468d2f17CdC',
    },
    tokenAddresses: { ...contractAddresses.erc20 },
    symbol: 'NFTART-BNB PCSV2 LP',
    tokenSymbol: 'NFTART LP',
    icon: '',
    pool: '100%',
  },
  //{
  //  id: 'NFTARTPOOL',
  //  version: 'Pool',
  //  name: 'NFTART',
  //  pid: 2,
  //  lpAddresses: {
  //    43114: '0x4604c1b8d5C345Dc151f38F8798a35F0Db19C95F',
  //    97: '0xB61DF995ed3FEA6bB2d51e7A9C2408e8C269A3D6',
  //    56: '0x2b7fC60FD13F32fEd8730113A14e3468d2f17CdC',
  //  },
  //  tokenAddresses: { ...contractAddresses.erc20 },
  //  symbol: 'NFTART',
  //  tokenSymbol: 'NFTART',
  //  icon: '',
  //  pool: '100%',
//  }

]
